<template>
  <div class="panel-inr-box">
    <div class="box-top-wrap d-flex align-center justify-space-between">
      <div>
        <h3 class="box-title">
          {{ $t("invoice.invoiceTitle") }}
        </h3>
      </div>
      <v-spacer></v-spacer>
      <button min-height="40" class="btn customBtn dark-gray-btn text-capitalize mr-2"
        @click="$router.push('/dashboard/billing-invoice/dashboard')">
        {{ $t("invoice.dasboard") }}
      </button>
      <button min-height="40" class="btn customBtn dark-gray-btn text-capitalize mr-2"
        @click="salesTaxRateDialog = true">
        {{ $t("invoice.salesTaxRate.title") }}
      </button>
      <button min-height="40" class="btn customBtn pattern-btn text-capitalize mr-2"
        @click="$router.push('/dashboard/billing-invoice/create')"
        :disabled="!salesTaxRate.sales_tax_rate || !salesTaxRate.invoice_start_from">
        <span>{{ $t("invoice.createInvoice") }}</span>
      </button>
    </div>
    <div class="custom-card vehicle-car">
      <div class="custom-box billing-box">
        <div class="filterField">
          <label class="form-label">{{ $t('interchange.modelYear') }}</label>
          <date-range-picker ref="picker" opens="right" :ranges="ranges()" :locale-data="{
            direction: 'ltr',
            format: 'MM-DD-yyyy',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
          }" v-model="dateRange" :alwaysShowCalendars="false" controlContainerClass="noneBorder">
            <template v-slot:input="picker">
              <v-text-field hide-details="auto" :placeholder="$t('invoice.createForm.date')" append-icon="mdi-calendar"
                readonly elevation="0" height="40" outlined dense :value="setDates(picker)"></v-text-field>
            </template>
          </date-range-picker>
        </div>

        <v-row class="">
          <v-col cols="4">
            <div class="white--text white green-gradient text-capitalize"
              style="height: 80px;border-radius: 6px;padding: 0px 10px 10px 10px;">
              <span>{{ $t("invoice.totalSales") }}</span>
              <span style="font-size: 25px !important; border-style: none;"><br />{{ invoiceStatistics.total_sales |
                USCurrencyfilter }}</span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="white--text white green-gradient text-capitalize"
              style="height: 80px;border-radius: 6px;padding: 0px 10px 10px 10px;">
              <span>{{ $t("invoice.totalTaxes") }}</span>
              <span style="font-size: 25px !important; border-style: none;"><br />{{ invoiceStatistics.total_taxes |
                USCurrencyfilter }}</span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="white--text white green-gradient text-capitalize"
              style="height: 80px;border-radius: 6px;padding: 0px 10px 10px 10px;">
              <span>{{ $t("invoice.pendingReceivables") }}</span>
              <span style="font-size: 25px !important; border-style: none;"><br />{{
                invoiceStatistics.pending_receivables |
                USCurrencyfilter }}</span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="white--text white green-gradient text-capitalize"
              style="height: 80px;border-radius: 6px;padding: 0px 10px 10px 10px;">
              <span>{{ $t("invoice.totalWorkorder") }}</span>
              <span style="font-size: 25px !important; border-style: none;"><br />{{ invoiceStatistics.workorder |
                USCurrencyfilter }}</span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="white--text white green-gradient text-capitalize"
              style="height: 80px;border-radius: 6px;padding: 0px 10px 10px 10px;">
              <span>{{ $t("invoice.totalRefunded") }}</span>
              <span style="font-size: 25px !important; border-style: none;"><br />{{ invoiceStatistics.refund |
                USCurrencyfilter
                }}</span>
            </div>
          </v-col>
        </v-row>

        <v-card-title class="v-card__title red--text pb-3 pl-3 pt-3">
          {{ $t("invoice.importantInfo") }}
        </v-card-title>

        <v-card-text v-html='$t("invoice.importantInfoText")'
          class="pa-0 pl-3 pr-3 black--text font-weight-regular"></v-card-text>
      </div>
    </div>
    <!-- 
            <v-card-text
              v-if="
                hasPaymentMethod && currentPaymentMethod && !updatePaymentMethod
              "
            >
              <label>
                <span>{{ $t("Current Payment Method") }}</span>
              </label>
              <v-text-field
                id="card-holder-name"
                type="text"
                hide-details="auto"
                outlined
                :label="$t('Card Holder Name')"
                color="green darken-4"
                class="mb-4"
                :value="currentPaymentMethod.billing_details.name"
                disabled="disabled"
              ></v-text-field>

              <div>
                <i
                  class="text-3xl fab"
                  :class="'fa-cc-' + currentPaymentMethod.card.brand"
                />

                <span class="text-xs">
                  &bull; &bull; &bull; &bull;
                  {{ currentPaymentMethod.card.last4 }}
                  ({{ currentPaymentMethod.card.exp_month }}
                  /
                  {{ currentPaymentMethod.card.exp_year }})
                </span>
              </div>

              <v-btn
                color="green accent-4"
                class="mr-3"
                @click="changePaymentMethod"
              >
                Change Payment Method
              </v-btn>
            </v-card-text>

            <v-card-text v-if="updatePaymentMethod">
              <label>
                <span>{{ $t("Manage Payment Method") }}</span>
              </label>
              <v-text-field
                id="card-holder-name"
                type="text"
                hide-details="auto"
                outlined
                :label="$t('Card Holder Name')"
                color="green darken-4"
                class="mb-4"
              ></v-text-field>

              <div id="card-element"></div>

              <v-btn
                color="green accent-4"
                class="mr-3"
                id="card-button"
                :data-secret="intent.client_secret"
              >
                Update Payment Method
              </v-btn>
            </v-card-text>
            -->

    <v-dialog hide-overlay :attach="true" v-model="salesTaxRateDialog" max-width="700px"
      :content-class="'hide-overflow-y image-slider'" persistent>
      <v-card>
        <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
          <div class="pa-2">
            <div class="pt-4 pb-0 red--text text-center" style="font-size: 22px;line-height: 1;"
              v-if="isAssociates == true && (!salesTaxRate.sales_tax_rate || !salesTaxRate.invoice_start_from)"
              v-html='$t("invoice.salesTaxRate.noteAssociates")'></div>
            <v-card-title> {{ $t("invoice.salesTaxRate.title") }}</v-card-title>
            <v-form>
              <v-container>
                <v-form class="mr-3">
                  <v-row>
                    <v-col cols="12" md="12">
                      <span>{{ $t('invoice.salesTaxRate.salesTaxRate') }}</span>
                      <v-text-field type="number" hide-details="auto" v-model="salesTaxRateForm.sales_tax_rate"
                        @input="$v.salesTaxRateForm.sales_tax_rate.$touch()"
                        @blur="$v.salesTaxRateForm.sales_tax_rate.$touch()"
                        :placeholder="$t('invoice.salesTaxRate.yardSalesTaxRate')" outlined
                        :error-messages="salesTaxRateErrors" :disabled="isAssociates == true"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <span>{{ $t('invoice.salesTaxRate.invoiceNumber') }}</span>
                      <v-text-field type="number" hide-details="auto" v-model="salesTaxRateForm.invoice_start_from"
                        @input="$v.salesTaxRateForm.invoice_start_from.$touch()"
                        @blur="$v.salesTaxRateForm.invoice_start_from.$touch()"
                        :placeholder="$t('invoice.salesTaxRate.invoiceNumberSalesTaxRate')" outlined
                        :error-messages="invoiceStartFromErrors"
                        :disabled="is_already_updated || isAssociates"></v-text-field>
                      <span v-html='$t("invoice.invoiceNoNote")'></span>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
                      @click="salesTaxRateDialog = !salesTaxRateDialog; isSalesTaxRateEdit = false;">
                      {{ $t("invoice.close") }}
                    </v-btn>
                    <v-btn v-if="isAssociates == false" height="40" large
                      color="green darken-4 green-gradient white--text text-capitalize" :loading="saving"
                      @click="saveSalesTaxRate"
                      :disabled="!salesTaxRateForm.sales_tax_rate || !salesTaxRateForm.invoice_start_from">
                      {{ $t("invoice.salesTaxRate.update") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarError" :timeout="3000" absolute top color="error" right>
      {{ apiErrorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";

export default {
  name: "BlilingInvoice",

  data() {
    return {
      userDetail: {},
      salesTaxRateDialog: false,
      isSalesTaxRateEdit: false,
      saving: false,
      salesTaxRateForm: {
        sales_tax_rate: null,
        invoice_start_from: null,
      },
      menu2: false,

      apiErrorMessage: null,
      snackbarError: false,
      is_already_updated: null,
      isAssociates: false,
      intentToken: null,
      currentPaymentMethod: null,
      updatePaymentMethod: false,

      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  validations: {
    salesTaxRateForm: {
      sales_tax_rate: { required },
      invoice_start_from: { required },
    }
  },
  watch: {
    dateRange: function (newVal, oldVal) {
      if (newVal.startDate && newVal.endDate) {
        this.fetchDasboardStatistics();
      }
    }
  },
  computed: {
    ...mapGetters({
      invoiceStatistics: "invoice/getInvoiceStatistics",
      salesTaxRate: "user/getSalesTaxRate",

      user: "user/getUser",
      intent: "payment/getSetupIntent",
      paymentMethod: "payment/getPaymentMethod",
    }),
    salesTaxRateErrors() {
      const errors = [];
      if (!this.$v.salesTaxRateForm.sales_tax_rate.$dirty) return errors;
      !this.$v.salesTaxRateForm.sales_tax_rate.required &&
        errors.push(this.$t("marketingCampaign.validations.nameIsRequired"));
      if (this.errors && this.errors.sales_tax_rate) {
        return this.errors.sales_tax_rate;
      }
      return errors;
    },
    invoiceStartFromErrors() {
      const errors = [];
      if (!this.$v.salesTaxRateForm.invoice_start_from.$dirty) return errors;
      !this.$v.salesTaxRateForm.invoice_start_from.required &&
        errors.push(this.$t("marketingCampaign.validations.numberIsRequired"));
      if (this.errors && this.errors.invoice_start_from) {
        return this.errors.invoice_start_from;
      }
      return errors;
    },


    // hasPaymentMethod() {
    //   return !!this.paymentMethod;
    // },
  },
  async mounted() {
    await this.fetchDasboardStatistics();
    await this.fetchSalesTaxRate();

    // await this.fetchPaymentMethod();
  },
  methods: {
    ...mapActions({
      updateSalesTaxRate: "user/updateSalesTaxRate",
      getInvoiceStatistics: "invoice/getInvoiceStatistics",
      getSalesTaxRate: "user/getSalesTaxRate",


      getPaymentMethod: "payment/getPaymentMethod",
      getSetupIntent: "payment/getSetupIntent",
      addPaymentMethod: "payment/addPaymentMethod",
    }),
    async saveSalesTaxRate() {
      this.$store.commit("setLoader", true);
      try {
        await this.updateSalesTaxRate(this.salesTaxRateForm);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        await this.fetchSalesTaxRate();
        this.$store.commit("setLoader", false);
        this.salesTaxRateDialog = false;
      }
    },
    async fetchSalesTaxRate() {
      this.$store.commit("setLoader", true);
      try {
        await this.getSalesTaxRate();
        if (this.salesTaxRate.status == true) {
          if (this.salesTaxRate) {
            this.salesTaxRateForm.sales_tax_rate = this.salesTaxRate.sales_tax_rate ? this.salesTaxRate.sales_tax_rate : null;
            this.salesTaxRateForm.invoice_start_from = this.salesTaxRate.invoice_start_from;

            if (this.salesTaxRateForm.invoice_start_from) {
              this.is_already_updated = true;
            }

            if (typeof this.salesTaxRate.parent_id == 'number') {
              this.isAssociates = true;
            }
          }
        } else {
          this.apiErrorMessage = this.salesTaxRate.message;
          this.snackbarError = true;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
        this.salesTaxRateDialog = false;
      }
    },
    async fetchDasboardStatistics() {
      this.$store.commit("setLoader", true);
      try {
        await this.getInvoiceStatistics({ date: [moment(this.dateRange.startDate).format("YYYY-MM-DD"), moment(this.dateRange.endDate).format("YYYY-MM-DD")] });
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
        this.salesTaxRateDialog = false;
      }
    },
    setDates(picker) {
      let startDate = (picker.startDate ? moment(picker.startDate).format('MM-DD-YYYY') + ' - ' : '');
      let endDate = (picker.endDate ? moment(picker.endDate).format('MM-DD-YYYY') : '');
      return startDate + endDate;
    },
    ranges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);
      var y = today.getFullYear(), m = today.getMonth();
      var thisMonthStart = new Date(y, m, 1);
      let thisMonthEnd = new Date(y, m + 1, 0, 11, 59, 59, 999);

      return {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last 7 Days': [new Date(y, m, today.getDate() - 6), today],
        'Last 30 Days': [new Date(y, m, today.getDate() - 29), today],
        'This year': [new Date(y, 0, 1), new Date(y, 11, 31)],
        'Last month': [new Date(y, m - 1, 1), new Date(y, m, 0)],
      }
    },



    async changePaymentMethod() {
      this.updatePaymentMethod = true;
      await this.setupIntent();
      await this.setupCardElement();
    },
    async fetchPaymentMethod() {
      this.$store.commit("setLoader", true);
      try {
        await this.getPaymentMethod();
        this.currentPaymentMethod = this.paymentMethod;
        if (this.currentPaymentMethod === null) {
          await this.changePaymentMethod();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async setupIntent() {
      this.$store.commit("setLoader", true);
      try {
        await this.getSetupIntent();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async setupCardElement() {
      this.$store.commit("setLoader", true);
      try {
        // SETUP CARD ELEMENT
        const stripe = await loadStripe(process.env.VUE_APP_PUBLIC_KEY);

        const elements = stripe.elements();
        const cardElement = elements.create("card");

        cardElement.mount("#card-element");

        // VERIFY CARD DETAIL
        const cardHolderName = document.getElementById("card-holder-name");
        const cardButton = document.getElementById("card-button");
        const clientSecret = cardButton.dataset.secret;
        cardButton.addEventListener("click", async () => {
          const { setupIntent, error } = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: { name: cardHolderName.value },
              },
            }
          );

          if (error) {
            // Display "error.message" to the user...
            console.log(error);
          } else {
            // The card has been verified successfully...
            console.log(setupIntent);
            await this.saveCardDetails(setupIntent);
          }
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async saveCardDetails(setupIntent) {
      this.$store.commit("setLoader", true);
      try {
        await this.addPaymentMethod({
          payment_method: setupIntent.payment_method,
        });
      } catch ({ message }) {
        console.log(message);
      } finally {
        this.$store.commit("setLoader", false);
        this.updatePaymentMethod = false;
        await this.fetchPaymentMethod();
      }
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}

.vue-daterange-picker {
  display: unset;
}

.noneBorder {
  border: none !important;
  width: 100%;
}
</style>
